<template>
  <v-container :style="{'max-width': '1300px'}" class=""> 
    
    
    
  <v-data-table
      @click:row="onClickRow"
      :headers="headers"
      v-model="selectedTableRows"
      show-select
      loading-text="Завантаження..."
      no-data-text="Немає даних для відображення"
      :items="abonents"
      :options.sync="options"
      :page.sync="page"
      :server-items-length="paginations.count"
      :loading="loading"
      class="elevation-1 row-cursor mt-5"
      :class="$vuetify.breakpoint.smAndDown ? $vuetify.theme.dark && 'td_background_dark' || 'td_background':''"
      :footer-props="footer"
      focusable
      :dense="switch_danse_table || $vuetify.breakpoint.smAndDown"
      :disable-sort="$vuetify.breakpoint.smAndDown"
      
    >
      <template v-slot:top>

        <v-toolbar :extended="$vuetify.breakpoint.xs"  flat color="secondary lighten-1" dark>
          <template
            v-slot:extension
            v-if="$vuetify.breakpoint.xs"
          >
            <v-spacer />
            <v-btn
              v-if="selectedTableRows.length > 0" 
              depressed
              class="primary--text"
              color="accent"
              @click="$refs.jobDeptorsModal.show(selectedTableRows)"
              >
              Заявка ({{ selectedTableRows.length }})
            </v-btn>
              <template v-if="selectedTableRows.length == 0">
                <v-btn :disabled="loading || options.page <= 1" icon @click="page -= 1">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span v-if="paginations.count" class="text-body-2">{{ options.page }} / {{ lastPage }} ( {{ paginations.count }} )</span> 
                <v-btn :disabled="loading || paginations.count == paginations.end_index" icon @click="page += 1">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </template>
            <v-spacer />
          </template>

          
          
          <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''"><h2 class="ml-2 accent--text">Боржники</h2></v-toolbar-title>
          
          <v-spacer></v-spacer>
          
          <template v-if="!$vuetify.breakpoint.xs">
            <v-btn
              v-if="selectedTableRows.length > 0" 
              depressed
              class="primary--text"
              color="accent"
              @click="$refs.jobDeptorsModal.show(selectedTableRows)"
              >
              Заявка ({{ selectedTableRows.length }})
            </v-btn>
            <template v-if="selectedTableRows.length == 0">
              <v-btn :disabled="loading || options.page <= 1" icon @click="page -= 1">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span v-if="paginations.count" class="text-body-2">{{ options.page }} / {{ lastPage }} ( {{ paginations.count }} )</span> 
              <v-btn :disabled="loading || paginations.count == paginations.end_index" icon @click="page += 1">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>
          </template>
          <v-spacer />
          
          <div title="Зменшити таблицю">
            <v-switch
            v-if="!$vuetify.breakpoint.smAndDown"
            
            class="ml-2 mr-1"
            v-model="switch_danse_table"
            hide-details
          ></v-switch>
          </div>
          
          <v-btn icon title="Поновити данні" :loading="loading" @click="getAbonents()">
            <v-icon>autorenew</v-icon>
          </v-btn>
          
          <v-btn title="Фільтри пошуку" icon @click="$refs.filtersAbonents.show()">
            <v-badge
            :value="countFilters > 0"
            color="accent"
            text="primary"
            :content="countFilters"
            dark
          >
            <v-icon>mdi-filter</v-icon>
            </v-badge>
          </v-btn>
          

        </v-toolbar>
      </template>

      <template
        v-if="countFilters > 0"
        v-slot:header="{ props: { headers } }"
      >
        <thead>
          <tr>
            <th :colspan="headers.length">
              Фільтри: 
              <keep-alive>
                <FiltersChips :filtersComponents="filtersComponents" v-on:clear-filter="clearFilter" :is_close="true"/>
              </keep-alive>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:header.data-table-select>
        
      </template>
        <template #item.data-table-select="{item}">
          <v-checkbox
            v-if="item && !item.user.is_orders"
            v-model="selectedTableRows"
            :value="item.user.id"
            hide-details
            class="pa-0 ma-0"
            @click.stop
          ></v-checkbox>
          <v-icon v-else color="grey" title="У цього абонента вже є заявки">mdi-information-outline</v-icon>
        </template>
      
    <!-- ---------------------------------------- Cols ----------------------------------------- -->
      <template #item.index="{ item }" style="background: #eee">
        {{ abonents.indexOf(item) + paginations.start_index }}
      </template>

      <template #item.full_name="{ item }">
        {{ item.user && item.user.full_name }}
      </template>
      <template #item.user__street="{ item }">
        {{ item.user && item.user.street.street || ''}}
      </template>
      <template #item.house="{ item }">
        {{ item.user && `${item.user.house}${item.user.letter || ''}`}}{{ item.user && item.user.corpus > 0 && ` (к.${item.user.corpus})` || '' }}
      </template>
      <template #item.flat="{ item }">
        {{ item.user && item.user.flat }}{{ item.user &&  item.user.tenand > 0 && ` (н.${item.user.tenand})` || '' }}
      </template>

      <template #item.operator_group="{ item }">
        {{ item.user && item.user.operator_group.name }}
      </template>
      <template #item.status="{ item }">
        <v-icon :color="item.status ? 'green' : 'deep-orange'">mdi-television</v-icon>
      </template>
      <template #item.tarif="{ item }">
        {{ item.tarif && item.tarif.name }}
      </template>

      <template #item.user_phones="{ item }">
        <v-badge
        
          :content="`+${item.user.user_phones.length - 1}`"
          :value="item.user && item.user.user_phones.length > 1"
          color="grey lighten-1"
          inline
        >
        {{item.user && item.user.user_phones.length != 0 && item.user.user_phones[0].phone || '' }}
        </v-badge> 
        <!--{{item && item.user_phones.length != 0 && item.user_phones.find(x => x.main).phone || '' }} <b>{{ item && item.user_phones.length > 1 && ` (+${item.user_phones.length - 1})` || '' }}</b>-->
      </template>

       

    <!-- ---------------------------------------- /Cols ----------------------------------------- -->
    <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
        {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
      </template>
      
    </v-data-table>
    <keep-alive>
      <Filters ref="filtersAbonents" v-on:apply-filters="checkGetAbonents()" for="debtors"/>
    </keep-alive>
    <keep-alive>
      <AddJobDeptorsModal ref="jobDeptorsModal" v-on:update-table="eventSendJob()" />
    </keep-alive>
  </v-container>
</template>

<script>
  import axios from "axios"
  import Filters from '@/components/Abonents/Filters.vue'
  import AddJobDeptorsModal from '@/components/Abonents/AddJobDeptorsModal.vue'
  import FiltersChips from '@/components/Abonents/FiltersChips.vue'

  import { mapGetters } from "vuex"
  export default {
    //props: [ 'search' ],
    name: 'abonents-table',
    components: {
      Filters,
      FiltersChips,
      AddJobDeptorsModal,
    },
    data: () => ({
      selectedAbonents: [],
      selectedTableRows: [],
      abonents: [],
      paginations: {},
      countFilters: 0,
      loading: false,
      switch_danse_table: JSON.parse(localStorage.getItem("setting-dense-table-abonents")) || false,
      options: {},
      page: 1,
      filtersComponents: [],
      headers: [
        { text: '#', value: 'index', sortable: false,  width: 50,},
        //{ text: '#', value: 'id', sortable: false,  width: 50},
        { text: 'П.І.Б.', value: 'full_name', sortable: false, },
        { text: 'ВУЛИЦЯ', value: 'user__street', },
        { text: 'БУД', value: 'house', sortable: false, },
        { text: 'КВ', value: 'flat', sortable: false, },
        { text: 'ОПЕРАТОР', value: 'operator_group', sortable: false,},
        { text: 'СТАТУС', value: 'status', sortable: false,},
        { text: 'БАЛАНС', value: 'balance', },
        { text: 'ТАРИФ', value: 'tarif', },
        { text: 'ТЕЛЕФОН', value: 'user_phones',  sortable: false,},
        
      ],
      footer: {
          "items-per-page-options": [10,25,50],
          "items-per-page-text": " ", //"Абонентів на сторінці:"
          "items-per-page-all-text": "Всі",
          "show-first-last-page": true,
          "show-current-page": true,
      },
    }),
    computed: {
      ...mapGetters({
        //rates: "filters/rates",
        //streets: "filters/streets",
        operators: "filters/operators",
        user: "auth/user"
      }),
      lastPage(){
          let rawLastPage = this.paginations.count / this.options.itemsPerPage
          return rawLastPage && Math.ceil(rawLastPage) || 1
        }
    },
    mounted (){
      this.$nextTick(function () {
        /** ---------- Додаємо збережений фільтер кількості тарифів (якщо є) --------------*/
        let filter_debtor = localStorage.getItem("filters-debtors-table-debtors")
        
        if(filter_debtor){
          this.$refs.filtersAbonents.filters.find(x => x.name == 'debtor').value = filter_debtor
          console.log('filter_debtor: ', filter_debtor, this.$refs.filtersAbonents.filters.find(x => x.name == 'debtor'))
        }
        /** ------------------------------------------------------------------------------ */
        this.createHeadersTable()
      })
    },
    watch: {
      search: function(){
        this.checkGetAbonents()
      },
      options: {
        handler (val, oldVal) {
          //console.log("oldVal.page")
          console.log("watch options debtors table")
          oldVal.page && this.getAbonents(); 
        },
        deep: true,
      },
      activeTableInet(){
        this.checkGetAbonents()
      },
      switch_danse_table(){
        //console.log("switch_danse_table")
        this.changeDenseTable()
      },
      selectedTableRows(){
        console.log(this.selectedTableRows)
      }
    },
    methods: {
      eventSendJob(){
        this.selectedAbonents = []
        this.selectedTableRows = []
        this.checkGetAbonents()
      },
      
      createHeadersTable(){
        let operatorIndex = this.headers.indexOf(this.headers.find(x => x.value == "operator_group")) 
        if(this.user?.operator_id == 1){ // якщо оператор "Altair"
          //
        }else{
          this.headers.splice(operatorIndex, 1)
        }
        this.checkGetAbonents()
      },
      onClickRow(item){
        this.$router.push({ name: 'abonent_info_debtors', params: { id: item.user.id }})
      },
      showRouter(){
        //console.log(this.$refs)
      },
      checkGetAbonents(){
        if(this.options.page == 1){
          this.getAbonents();
        }else{
          this.options.page = 1;
        }
      },
      async getAbonents(){
          //console.log(this.$route.params)
          this.loading = true
          try {
            const { data } = await axios.get("/api/cabletv/debtor_users/", {params: this.filterFormat()});
            this.paginations = data.paginations
            this.abonents = data.results
            this.searchHint = data.paginations.count
            //console.log(data)
            this.loading = false
          } catch (error) {
            //console.log(error)
            this.loading = false
          }    
      },
      clearFilter(filter_name){
        this.$refs.filtersAbonents.filters.find(x => x.name == filter_name).value = ''
        this.$refs.filtersAbonents.apply()
      },
      getFiltersOfComponents(){
        return this.$refs.filtersAbonents.filters.filter(x => x.value && x.value != '')
      },
      filterFormat() {
        this.countFilters = 0
        const temp = {};

        /**----------- СОРТУВАННЯ -----------*/
        if(this.options.sortBy[0] && this.options.sortBy[0] == 'user__street'){
          if (this.options.sortDesc[0]) temp.ordering = "user__street__street',user__house,user__letter,user__flat"
          else temp.ordering = "-user__street__street,-user__house,-user__letter,-user__flat"
        }else if(this.options.sortBy[0]){
          temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0]
          temp.ordering = temp.ordering + ",user__street__street,user__house,user__letter,user__flat" 
        }
        /**-------------------------------- */

        /*
        temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0];
        */
        temp.itemsPerPage = this.options.itemsPerPage;
        temp.page = this.options.page;
        
        //if(this.search) temp.search = this.search
        
        
        /* получение значений фильтрации и прощет колличества */
        this.filtersComponents = this.getFiltersOfComponents()
        this.filtersComponents.forEach(filter => temp[filter.name] = filter.value + '');
        this.countFilters = this.filtersComponents.length
        /** ---------- Зберігаємо фільт кількості тарифів в сховище --------------- */
        
        localStorage.setItem("filters-debtors-table-debtors", temp.debtor || '')
        
        /* ------------------------------------------------------------------------ */

        if(this.search){
          //console.log(this.searchParse(this.search))
          Object.assign(temp, this.searchParse(this.search))
        } 
        
        return temp; 
      },
      changeDenseTable (){
        localStorage.setItem("setting-dense-table-abonents", this.switch_danse_table);
      }

    },
  }
</script>

<style>
  .row-cursor tr {
    cursor: pointer;
  }
  .td_background tr td:first-child{
    background: #eee
  }
</style>
