<template>
        
        <v-dialog v-model="dialog" persistent max-width="500">
            
            <v-card>
            <v-card-title class="">
            <span class="headline ">Створити заявки</span>
            </v-card-title>
            
            <v-card-text>
                
            <v-container class="mt-6">
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>       
                        <v-col v-for="field in fields" :key="field.name" cols="12" class="py-2">
                            <v-select v-if="field.type == 'select'"
                            dense
                            outlined
                            hide-details="auto"
                            :label="field.title"
                            v-model="form_data[field.name]"
                            :name="field.name"
                            :rules="field.rules && textRules || notRules"
                            :items="field.values"
                            item-text="name"
                            item-value="id"
                            :disabled="field.disabled"
                            >
                            </v-select>

                            <v-textarea v-else-if="field.type == 'textarea'"
                                dense
                                rows="5"
                                :readonly="field.readonly"
                                outlined
                                hide-details="auto"
                                :label="field.title"
                                v-model="form_data[field.name]"
                                :name="field.name"
                                :rules="field.rules && textRules || notRules"
                                :type="field.type"
                                :disabled="field.disabled"
                            ></v-textarea>
                            
                            <v-text-field v-else
                            dense
                            outlined
                            hide-details="auto"
                            :label="field.title"
                            v-model="form_data[field.name]"
                            :name="field.name"
                            :rules="field.rules && textRules || notRules"
                            :type="field.type"
                            :disabled="field.disabled"
                            >
                            </v-text-field>
                        
                        </v-col>
                            
                        
                           
                        
                    
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                
                <v-spacer />
                <v-btn :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Створити</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
    export default {
        name: 'add-job-debtors-modal',
        data: () => ({
            abonents_id_list: [],
            abonents: [],
            loadingSubmit:false,
            dialog: false,
            valid: true,
            form_data: {abonents_list: []},
            fields: [
                {title: "Дата призначення",     name: "date",     rules: true,  type: "date",      disabled: false, value: "" },
                {title: "Тип заявки",      name: "type", rules: true,  type: "select",    disabled: false, value: "", values: [{name: "Інформування", id: 5, parametr: 1},{name: "Відключення", id: 2, parametr: 0}]},
                {title: "Абоненти",      name: "abonents_list", rules: false,  type: "textarea", readonly: true,    disabled: false, value: ""},
               
            ],
            
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            //
        },
        mounted(){

        },
        methods: {
            show(abonents_id_list){
                this.abonents_id_list = abonents_id_list
                this.form_data.type = 5
                this.form_data.date = new Date().toDateInputValue()
                this.getAbonents()
                
                this.dialog = true
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog (){
                this.dialog = false
                this.reset() 
                this.abonents = []
                this.abonents_id_list = []
                this.form_data = {abonents_list: []}
                
            },
            getAbonentIdList(){
                let temp_list_id = []
                for(let ab in this.abonents){
                    temp_list_id.push({id: ab})
                }
                return temp_list_id
            },
            sendForm(){
                //console.log(this.form_data)
                
                this.loadingSubmit = true;
                axios({
                    headers: { "Content-type": "application/json; charset=UTF-8" },
                    method: "POST",
                    url: `/api/service/bulk_order_disable/`,
                    data: {date_due: this.form_data.date, order_type: this.form_data.type, users: this.getAbonentIdList()}
                })
                .then(() => {
                    //console.log(response)
                    this.$emit('update-table')
                    this.$router.app.$snack.show("Заявки створено!")
                    this.closeDialog()
                })
                .catch(err => {
                    //reject(err)
                    //console.log(err);
                    this.$router.app.$sheet.show("Помилка!", err);
                    
                    //console.log(this.$route.query.next)
                })
                .finally(() => (this.loadingSubmit = false))
            },
            getAbonents(){
                axios({
                    headers: { "Content-type": "application/json; charset=UTF-8" },
                    method: "POST",
                    url: `/api/cabletv/get_list_ids/`,
                    data: {user_ids_list: this.abonents_id_list}
                })
                .then(response => {
                    this.abonents = response.data
                    let temp_list = ''
                    for (let ab in this.abonents){
                        temp_list += this.abonents[ab] + '\n'
                    }
                    this.form_data.abonents_list = temp_list
                })
                .catch(() => {
                    //
                })
                .finally(() => (this.loadingSubmit = false))
                }
            },
        
    }
</script>