<template>
  <div class="">
    
    <transition name="page" mode="out-in">
      <keep-alive>
        <router-view v-if="$route.name == 'abonent_info_debtors'"></router-view>

        <DebtorsTable />
    </keep-alive>
    
    
    </transition>
  </div>
</template>

<script>
import DebtorsTable from '@/components/Abonents/DebtorsTable.vue'
import '@/components/Tools'
export default {
  name: 'Debtors',
  components: {
    DebtorsTable
  },
  data: () => ({
    //
  }),
  mounted (){
    //  
  },
  methods: {
    //
  }
}
</script>

